import { getNotReadMessageCount } from '~/api/user';
import { setSession } from '~/utils/storage';
export const state = () => ({
  unreadMessage: 0, // 未读消息-统计
});

export const getters = {};

export const mutations = {
  SET_MESSAGE(state, value) {
    state.unreadMessage = value;
  }
};

export const actions = {
  getNotReadMessageCountFn({ commit }) {
    getNotReadMessageCount({ loginType: 'mobile' }).then(res => {
      if (res.code == '0000') {
        commit('SET_MESSAGE', res.data);
        setSession('SET_MESSAGE', res.data);
      }
    });
  },
};