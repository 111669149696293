export const state = () => ({
  cartArray: [],
  partyId: '',
  searchList: [],
  classifyList: [],
  bannerData: [],
  notice: []
});

export const mutations = {
  /**
   * 添加购物车
   * @param {Array} state
   * @param {Array} value
   */
  addCart(state, value) {
    state.cartArray = value;
  },
  /**
   * id
   * @param {String} state
   * @param {String} value
   */
  setPartyId(state, value) {
    state.partyId = value;
  },
  /**
   * 首页热门搜索
   * @param {Array} state
   * @param {Array} value
   */
  setHotList(state, value) {
    state.searchList = value;
  },
  /**
   * 首页全部分类
   * @param {Array} state
   * @param {Array} value
   */
  setCategoryAll(state, value) {
    state.classifyList = value;
  },
  /**
   * 首页轮播图
   * @param {Array} state
   * @param {Array} value
   */
  setBanner(state, value) {
    state.bannerData = value;
  },
  /**
   * 首页公告
   * @param {Array} state
   * @param {Array} value
   */
  setNotice(state, value) {
    state.notice = value;
  }
};